import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  awards: GatsbyTypes.ContentfulAwardConnection;
};

export const AwardCards: React.FC<Props> = ({ awards }) => {
  return (
    <>
      <style jsx>{`
        .articles {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
        .article {
          background-color: ${colors.white};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 15px;
          transition: box-shadow 0.3s ease;
          width: calc(100% / 3 - 30px);
        }
        .article:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        }
        .article:hover .eyecatch {
          opacity: 0.8;
        }
        .eyecatch {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 6px 6px 0 0;
          padding-top: 60%;
          transition-property: background-size, opacity;
          transition-duration: 0.4s;
          transition-timing-function: ease;
          width: 100%;
        }
        .body {
          display: flex;
          flex-direction: column;
          padding: 20px;
        }
        .title {
          color: ${colors.defaultText};
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
        }
        .companyName {
          color: ${colors.defaultText};
          font-size: 14px;
          margin: 10px 0;
        }
        .date {
          color: ${colors.littleBrightGray};
          font-size: 15px;
          font-weight: 500;
          margin-top: auto;
        }
        @media (${breakPoints.sp}) {
          .article {
            width: 100%;
            margin: 20px 0;
          }
        }
      `}</style>
      <div className="articles">
        {awards.edges.map((edge) => {
          const { node } = edge;

          return (
            <article key={`Awards_${node.contentful_id}`} className="article">
              <Link to={`${PATH.AWARD.to}/${node.contentful_id}`}>
                <div className="eyecatch" style={{ backgroundImage: `url(${node.eyecatch?.fluid?.src})` }} />
                <div className="body">
                  <h2 className="title">{node.title}</h2>
                  {node?.company && <div className="companyName">{node.company.name}</div>}
                  <div className="date">{dayjs(node.publishedAt).format("YYYY.MM.DD")}</div>
                </div>
              </Link>
            </article>
          );
        })}
      </div>
    </>
  );
};
