import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { PageHeading } from "~/atoms/PageHeading";
import { AwardCards } from "~/molecules/AwardCards";
import { Pager } from "~/molecules/Pager";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { siteWidth } from "~/utils/variables";

type Props = {
  data: {
    awards: GatsbyTypes.ContentfulAwardConnection;
  };
  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
    times: number;
    year: string;
    breadCrumbPath: string[];
    parentPath: string;
  };
};

const Awards: React.FC<Props> = ({ data, pageContext }) => {
  const { times, year, currentPage, totalPages } = pageContext;
  const { awards } = data;
  const breadCrumbItems = [
    PATH.TOP,
    PATH.EVENTS_AWARD,
    {
      name: `${year}年受賞企業`,
      to: `/awards/${year}/`,
    },
  ];

  console.debug(pageContext)

  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.bgGrayGradient};
          padding-bottom: 50px;
        }
        .container {
          display: flex;
          margin: 0 auto;
          max-width: ${siteWidth.default};
          padding: 30px 0 50px;
        }
      `}</style>
      <Layout
        title={`第${times}回受賞企業インタビュー｜Refcome (リフカム) - リファラル採用を見える化し、共にカイゼンする伴走型サービス`}
        ogpDescription={`Referral Recruiting AWARD 第${times}回を受賞された企業の皆様にインタビューしました`}
        breadCrumbItems={breadCrumbItems}
      >
        <div className="wrapper">
          <PageHeading title={`第${times}回受賞企業インタビュー`}></PageHeading>
          <div className="container">
            <AwardCards awards={awards} />
          </div>
          <Pager currentPage={currentPage} totalPages={totalPages} basePath={`${PATH.AWARD}/${year}`} />
        </div>
      </Layout>
    </>
  );
};

export default Awards;

export const AwardsQuery = graphql`
  query Awards($year: Int!) {
    awards: allContentfulAward(filter: { eventYear: { eq: $year } }, sort: { fields: publishedAt, order: DESC }) {
      totalCount
      edges {
        node {
          id
          contentful_id
          eventYear
          eyecatch {
            fluid {
              src
            }
          }
          title
          createdAt(fromNow: false)
          publishedAt(fromNow: false)
          company {
            name
            id
          }
        }
      }
    }
  }
`;
